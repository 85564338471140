////////////////////////////////////////////////////////////////////////////////
// Units
////////////////////////////////////////////////////////////////////////////////

/// @see https://codepen.io/marknotton/pen/ExPbNGe
/// @see https://github.com/marknotton/doggistyle/blob/master/library/src/create/_units.scss

/// This units creator will render six positive and six negative sass variables for 
/// gaps, spacers, and gutters relative to the base values defined below. 

@forward 'create/units' with (

  /// Gaps ------------------------------------------------------------------------
  /// Gaps are an absolute unit (px) intended to be used on individual elements within a componented. 
  /// The aim is to retain consistant spacing amongst block, inline, table and group html elements.
  /// Gaps must use an absolute and static 'px' unit. It should not be relative to any
  /// parent container and is not intended for font sizes, so you should not use 'rem' or 'em'. 
  /// @result : $gap0, $gap, $gap2, $gap3, $gap4, $gap5 , $gap6, $gap0-, $gap-, $gap2-, $gap3-, $gap4-, $gap5- and $gap6-
  
  $gap : 16px,

  // Spacers ------------------------------------------------------------------------
  // Spacers are a relative viewport height (vh) unit intended to be used on a componented level.
  /// The aim is to retain consistant top and bottom spacing amongst 'section' and 'custom' elements.
  /// Spacers will equate to something like: `($spacer * x) + $gap`. 
  /// The '$gap' is added so that vh spacing will never scale too close to 0px. 
  /// Spacers must use a 'vh' unit so they are relative to the users viewport height-wise.
  /// They should primarily be used for the top and bottom margins on a component level.
  /// @result : $spacer0, $spacer, $spacer, $spacer, $spacer4, $spacer5, $spacer6, $spacer0-, $spacer-, $spacer-, $spacer-, $spacer4-, $spacer5- and $spacer6-
  
  $spacer : 4vh, 

  // Gutters ------------------------------------------------------------------------
  // Gutters are a relative viewport width (vw) unit intended to be used on a parent level;
  // for example, a wrapper container or body with nested components. 
  // The aim is to retain consistant left and right spacing amongst 'section' and 'custom' elements.
  /// Gutters will equate to something like: `($gutter * x) + $gap`. 
  /// The '$gap' is added so that vw spacing will never scale too close to 0px. 
  /// Gutters must use a 'vw' unit so they are relative to the users viewport width-wise. 
  /// They should primarily be used for the left and right padding or margin on a parent level.
  /// As a suggestion, this gutter constant should be half the size of the spacer value.
  /// @result : $gutter0, $gutter, $gutter2, $gutter3, $gutter4, $gutter5, $gutter6, $gutter0-, $gutter-, $gutter2-, $gutter3-, $gutter4-, $gutter5- and $gutter6-
  
  $gutter : 3vw, 

);

$border-radius : var(--border-radius, 6px);
$sidebar-width : 500px;