@use '_modules' as *;

search-bar {
  --font-size: 1.1em;
  --border-radius: 30px;
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  width: 100%;
  max-width: 500px;
  font-size: 1.4em;
  z-index: 3;

  &::after {
    @include loader;
    left: auto;
    right: $gap;
  }

  &.loading::after {
    @include loader-animation;
  }

  &:not(.showing-suggestions) {
    ul.results {
      display: none;
    }
  }

  &.showing-suggestions input[type='search'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  svg {
    color: white;
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 1;
    background-color: $red;
    height: auto;
    width: 100%;
    height: 46px;
    padding: 10px;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    margin-left: 4px;
  }

  @include form-input('search') {
    --border-radius: 30px;
    background-color: white;
    border: none;
    grid-column: 1 / -1;
    grid-row: 1;
    padding-left: calc(var(--font-size) + #{$gap3} + 8px);
    color: $font-colour;
    &::placeholder {
      font-size: 0.72em;
      color: $blue;
    }
  }

  ul.results {
    width: 100%;
    position: absolute;
    top: calc(100% - 1px);
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-top: 1px dashed $grey4;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    max-height: 305px;
    padding: 0 $gap;
    background-color: white;
    box-shadow: 0px 6px 16px -6px #6a6a6a;
    li {
      background-color: white;
      transition: background-color $transition;
      cursor: pointer;
      position: relative;

      + li {
        border-top: 1px solid $grey3;
      }
      mark {
        @include mark-reset();
        color: $blue5;
      }
      a {
        text-decoration: none;
        padding: $gap0 $gap;
        display: block;
        text-align: left;
        font-size: font-size(12);
        line-height: 1.2;
        &:focus {
          outline: none;
        }
      }
      &.focus a {
        background-color: $blue4;
        .type,
        & {
          color: white;
        }
      }
      .type {
        display: block;
        color: $grey;
        font-size: 0.7em;
        order: 1;
        transition: color $transition;
      }
      &.no-result {
        background-color: $blue;
        margin-top: -1px;
        a {
          color: white;
        }
      }
      &:nth-of-type(even) {
        background-color: $grey3;
      }
      @include hover {
        background-color: $grey4;
      }
    }
  }
}
