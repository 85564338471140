search-bar {
  --font-size: 1.1em;
  --border-radius: 30px;
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  width: 100%;
  max-width: 500px;
  font-size: 1.4em;
  z-index: 3;
}
search-bar::after {
  --size: 12px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, #969695);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
  left: auto;
  right: var(--gap);
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
search-bar.loading::after {
  opacity: 1;
  animation-play-state: running;
}
search-bar:not(.showing-suggestions) ul.results {
  display: none;
}
search-bar.showing-suggestions input[type=search] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
search-bar svg {
  color: white;
  grid-column: 1;
  grid-row: 1;
  position: relative;
  z-index: 1;
  background-color: #DF3035;
  height: auto;
  width: 100%;
  height: 46px;
  padding: 10px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  margin-left: 4px;
}
search-bar input[type=search] {
  --font-colour: #6A6A6A;
  --background-colour: #FFFFFF;
  --border-radius: var(--border-radius, 6px);
  --border-colour: var(--font-colour);
  --border: 1px solid var(--border-colour);
  --padding: var(--gap) var(--gap);
  border-radius: var(--border-radius, 6px);
  background-color: var(--background-colour);
  border: var(--border);
  padding: var(--padding);
  font-size: 0.875em;
  color: var(--font-colour);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  --border-radius: 30px;
  background-color: white;
  border: none;
  grid-column: 1/-1;
  grid-row: 1;
  padding-left: calc(var(--font-size) + calc(var(--gap) * 3) + 8px);
  color: #6A6A6A;
}
search-bar input[type=search]::placeholder {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
search-bar input[type=search]:focus {
  outline-color: #131f6b;
}
search-bar input[type=search]:focus::placeholder {
  color: transparent;
}
search-bar input[type=search].autocomplete:not([value=""]):focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
search-bar input[type=search]::placeholder {
  font-size: 0.72em;
  color: #131f6b;
}
search-bar ul.results {
  width: 100%;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px dashed #dcdcdc;
  border-bottom-left-radius: var(--border-radius, 6px);
  border-bottom-right-radius: var(--border-radius, 6px);
  max-height: 305px;
  padding: 0 var(--gap);
  background-color: white;
  box-shadow: 0px 6px 16px -6px #6a6a6a;
}
search-bar ul.results li {
  background-color: white;
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  cursor: pointer;
  position: relative;
}
search-bar ul.results li + li {
  border-top: 1px solid #F4F4F4;
}
search-bar ul.results li mark {
  background-color: initial;
  color: inherit;
  border-radius: initial;
  padding: initial;
  transition: initial;
  color: #0095FF;
}
search-bar ul.results li a {
  text-decoration: none;
  padding: calc(var(--gap) * 0.5) var(--gap);
  display: block;
  text-align: left;
  font-size: 0.75em;
  line-height: 1.2;
}
search-bar ul.results li a:focus {
  outline: none;
}
search-bar ul.results li.focus a {
  background-color: #10124d;
}
search-bar ul.results li.focus a .type, search-bar ul.results li.focus a {
  color: white;
}
search-bar ul.results li .type {
  display: block;
  color: #969695;
  font-size: 0.7em;
  order: 1;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
search-bar ul.results li.no-result {
  background-color: #131f6b;
  margin-top: -1px;
}
search-bar ul.results li.no-result a {
  color: white;
}
search-bar ul.results li:nth-of-type(even) {
  background-color: #F4F4F4;
}
@media (hover: hover) {
  search-bar ul.results li:hover {
    background-color: #dcdcdc;
  }
}