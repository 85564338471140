@use '../_variables' as *;
@use 'modules/colour/encode' as colour;
@use "font";

@mixin _base-styling {
  
  --font-colour : #{$font-colour};
  --background-colour : #{$white};
  --border-radius : #{$border-radius};
  --border-colour : var(--font-colour);
  --border : 1px solid var(--border-colour);
  --padding : #{$gap} #{$gap};

  border-radius:$border-radius;
  background-color:var(--background-colour);
  border:var(--border); 
  padding:var(--padding); 
  font-size:font.size(14); 
  color:var( --font-colour);

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    transition: color $transition;
  }
  &:focus {
    &::placeholder {
      color:transparent;
    }
    outline-color : $blue; 
  }
  &.autocomplete:not([value=""]):focus {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@mixin all-inputs() {
  input[type="text"], input[type="email"], input[type="number"], select, textarea {
    @content;
  }
}

@mixin input($type:null) {

  $selector : 'input:not([type="checkbox"]):not([type="radio"])';

  @if type-of($type) != 'null' {
    $selector : 'input[type="#{$type}"]';
  }

  #{$selector} {
    @include  _base-styling; 
    @content;
  }

}

@mixin select($stroke : $black) {

  select {
    @include  _base-styling; 
    -webkit-appearance: none; 
    background-repeat:no-repeat;
    background-position:calc(100% - #{$gap}) center; 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='"+colour.encode($stroke)+"' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");    
    @content;
  }

}

@mixin checkbox() {

}

@mixin textarea() {

  textarea {
    @include  _base-styling; 
    @content;
  }

}