@use '../_variables' as *;
@use 'modules/colour/encode' as colour;


@mixin loader($size : 12px, $colour : $grey) {
  --size : #{$size}; 
  content:"";
  position: absolute;
  top:calc(50% - (var(--size) / 2)); 
  left:calc(50% - (var(--size) / 2)); 
  opacity: 0;
  width:var(--size); 
  height:var(--size);
  border-radius: 50%;
  border:2px solid var(--loader-colour, $colour);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s $ease-in-out;
  animation-play-state: paused;
  @keyframes loader-rotate {
    from {transform: rotate(180deg); }
    to {transform: rotate(0deg); }
  }
  @content;
}

@mixin loader-animation { 
  opacity:1; 
  animation-play-state: running;
  @content;
}